@import '~app/mixins';

.caption {
  color: $color-blue-dark;
  font-size: 16px;
  margin-bottom: 4px;
}

.instructions {
  font-size: 12px;
  color: $color-grey;
  margin-bottom: 6px;
}

.buttons {
  margin-top: 20px;
  text-align: center;
  display: flex;
  justify-content: space-between;

  button + button {
    margin-left: 10px;
  }
}

.popup {
  overflow-y: auto;
}

.editor {
  width: 1125px;
  outline: none;

  :global {
    .fr-box .fr-wrapper {
      transform: scale(0.36);
      transform-origin: top left;
      width: 278%;
      height: 400px;
      min-height: 400px;
    }

    .fr-box .fr-placeholder {
      font-size: 32px !important;
      line-height: 40px !important;
    }
  }
}

.mainSection {
  width: 800px;
}

.sideSection {
  width: 300px;

  .title {
    color: $color-grey;
    margin-bottom: 3px;
    font-size: 13px;
    background-color: #eee;
    padding: 3px;
  }

  .editSection {
    margin-bottom: 12px;
  }

  .elementButtons {
    display: flex;

    button {
      flex: 1;
      white-space: nowrap;

      + button {
        margin-left: 6px;
      }
    }
  }

  .backgroundColors {
    display: flex;
    justify-content: space-between;

    .backgroundColor {
      flex: 1;

      + .backgroundColor {
        margin-left: 6px;
      }

      .color {
        border: 2px solid #aaa;
        padding: 6px 20px;
        text-align: center;
        border-radius: 15px;
        margin-top: 5px;
      }

      &.enabled {
        .color {
          cursor: pointer;
        }
      }
    }
  }
}

.grayInstructions {
  color: $color-grey-light;
  font-style: italic;
  font-size: 12px;
  text-align: center;
  white-space: nowrap;
  margin: 8px 0;
}

.sectionTitle {
  display: flex;
  justify-content: space-between;
  color: $color-grey;
  font-weight: bold;
  margin-bottom: 8px;
  padding-bottom: 3px;
  border-bottom: 1px solid $color-grey;

  .placeholderDropdown {
    width: 200px;
  }
}

.raisedPanel {
  box-shadow: 2px 2px 5px grey;
  padding: 6px 10px;

  .sectionTitle {
    .blueLink {
      color: blue;
      font-size: 12px;
      padding: 0;
      margin: 0;
      width: auto;
    }
  }
}

.topSection {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  .sideSection {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .controls {
    height: 50px;
  }

  .raisedPanel {
    .images {
      display: flex;
      flex-wrap: wrap;
      align-content: start;
      justify-content: space-between;
      overflow-y: auto;
      height: 200px;
      align-items: start;

      .contentTile {
        width: 50%;
      }
    }
  }
}

.contentTile {
  margin-top: 10px;
  cursor: pointer;
  padding: 5px;
  border: 2px solid transparent;
  border-radius: 5px;

  .title {
    color: $color-grey;
    margin-bottom: 2px;
    font-size: 13px;
  }

  &.selected {
    @include primary-border-color();

    .title {
      font-weight: bold;
    }
  }

  .tile {
    height: 100px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;

    img {
      max-width: 100%;
      max-height: 100%;
    }

    .buttons {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 0;

      .btn {
        display: none;
        width: 100px;
        margin: 0;

        + .btn {
          margin: 3px 0 0;
        }
      }

      div {
        color: $color-grey-light;
      }
    }

    &.empty {
      border: 2px dashed $color-grey-light;
      border-radius: 10px;
      cursor: default;
    }

    &:hover {
      .buttons {
        div {
          display: none;
        }

        .btn {
          display: inline-block;
        }
      }
    }
  }
}

.bottomSection {
  display: flex;
  justify-content: space-between;

  .wysiwyg {
    margin-top: 6px;
  }

  .raisedPanel {
    height: 100%;
  }

  .mainSection {
    height: 220px;
  }

  .textContainer {
    height: 180px;
    overflow-y: auto;

    .textElement {
      cursor: pointer;

      + .textElement {
        margin-top: 6px;
        padding-top: 6px;
        border-top: 1px solid $color-grey-dark;
      }

      .textContent {
        &.selected {
          background-color: lightblue;
        }

        .textTitleContainer {
          display: flex;
          justify-content: space-between;
          padding: 3px 0;

          .textTitle {
            color: $color-grey-dark;
            margin-bottom: 2px;
            font-size: 13px;
            font-weight: bold;
          }

          .clickToAdd {
            color: $color-grey-light;
            font-style: italic;
            font-size: 12px;
            margin-right: 4px;
          }
        }

        .text {
          overflow: hidden;
          max-height: 34px;

          * {
            font-size: 13px !important;
            color: $color-grey !important;
            font-style: italic !important;
            background-color: transparent !important;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          p {
            margin: 0;
          }
        }
      }
    }
  }
}
